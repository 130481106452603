import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Button } from '../components/patterns/button';

import promoPhoto from '../images/pt-promo-photo.jpg';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="container">
      <h1 className="visuallyhidden">Pulling Teeth</h1>

      <section>
        <h2 className="h1">Funerary CD</h2>
        <img
          src="https://f4.bcbits.com/img/a1457582257_16.jpg"
          alt="Pulling Teeth - Funerary CD"
          width="480"
          height="480"
          decoding="async"
          style={{ marginBottom: '1.5rem' }}
        />
        <p>Pick up a copy of Funerary on CD, or purchase digitally.</p>
        <Button
          ctaText="Buy Now"
          ctaUrl="/funerary-cd"
          ariaLabel="Buy the Funerary CD"
          isSolid
        />
      </section>

      <section>
        <h2 className="h1">Heretic Video</h2>
        <div className="iframe-container iframe-container-4x3">
          <iframe
            width="1120"
            height="630"
            src="https://www.youtube.com/embed/9inzBm9Y-z0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </section>

      <section>
        <h2 className="h1">Listen</h2>
        <div className="row">
          <div className="col-2" style={{ flexGrow: 1 }}>
            <iframe
              title="Pulling Teeth Top Spotify Tracks Playlist"
              src="https://open.spotify.com/embed/playlist/2NmKeFocp7vo6APamhtYaZ?utm_source=generator&theme=0"
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            />
          </div>
          <div className="col-4">
            <img
              src={promoPhoto}
              alt="The members of the band standing in an empty room with paper bags on their heads with odd faces drawn on them."
              loading="lazy"
              width="1500"
              height="1200"
              decoding="async"
            />
          </div>
        </div>
      </section>
    </div>
  </Layout>
);
export default IndexPage;
