import React from 'react';

const IconLoader = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="transparent"
      stroke="currentColor"
      d="M23.3,11.83c0,6.32-5.06,11.44-11.29,11.44S.7,18.15,.7,11.83"
    />
    <rect fill="transparent" />
    <animateTransform
      attributeName="transform"
      attributeType="XML"
      type="rotate"
      dur="1s"
      from="0"
      to="360"
      repeatCount="indefinite"
    />
  </svg>
);

export default IconLoader;
