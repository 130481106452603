import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { IconExternal } from '../icons/external';
import IconLoader from '../icons/loader';

export const Button = ({
  ariaLabel,
  className,
  ctaText,
  ctaUrl,
  isDisabled,
  isLoading,
  isReversed,
  isSolid,
  hasIcon,
  onClick,
}) => {
  const buttonClasses = classNames(
    'button',
    {
      'button--reversed': isReversed,
      'button--solid': isSolid,
    },
    className
  );

  if (onClick) {
    return (
      <button
        className={buttonClasses}
        type="button"
        aria-label={ariaLabel}
        onClick={onClick}
        disabled={isDisabled}
      >
        {isLoading && (
          <span>
            <IconLoader />
          </span>
        )}
        <span>{ctaText}</span>
      </button>
    );
  }

  return (
    <a className={buttonClasses} href={ctaUrl}>
      <span>{ctaText}</span>{' '}
      {hasIcon && <IconExternal className="button__icon" />}
    </a>
  );
};

Button.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string,
  isDisabled: PropTypes.bool,
  hasIcon: PropTypes.bool,
  isLoading: PropTypes.bool,
  isReversed: PropTypes.bool,
  isSolid: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  ariaLabel: null,
  className: '',
  ctaUrl: '',
  isDisabled: false,
  hasIcon: false,
  isLoading: false,
  isReversed: false,
  isSolid: false,
  onClick: null,
};
